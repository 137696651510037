<template>
  <div class="shop" style="margin-top: 15px;">
    <div class="box p20 bsbb mb40">
      <div class="tit df aic jcsb">
        <p class="fz28 fw7">{{ goodsList.store_name }}</p>
        <div class="img df aic jcc">
          <img src="../../assets/images/xg/phone.png" />
        </div>
      </div>
      <p class="fz16">{{ goodsList.store_address }}</p>
      <div class="fz28 fw7 mt40 mb20">商家信息</div>
      <div class="fz16" style="color:#999">
        <p class="mb20">商家品类 : 水果店</p>
        <p>营业时间 : 08:00-23:00</p>
      </div>
      <div class="df aic jcc mt20">
        <van-button plain round hairline type="default">查看营业资质</van-button>

      </div>
    </div>
    <div class="waiter p20 bsbb df fdc">
      <div class="fz28 fw7">商家服务</div>
      <div class="tips df fdc mt10">
        <span>支持预定</span>
        <div>商户支持提前预定服务，您可选择期望送达时间</div>
        <span>支持自取</span>
        <div>商户支持下单后到店自取</div>
        <span>开发票</span>
        <div>商户支持开发票，你可下单时填写抬头</div>
      </div>
    </div>
    <div class="report fz36 fw7 df aic jcc">
      举报商家
    </div>
    <footer></footer>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { Toast } from "vant";
import { getGoodsList, createOrderReq, CommodityManagementClass } from "../../utils/api";
import { useRouter } from "vue-router";
const router = useRouter();
const id = ref(router.currentRoute.value.query.id)
const page = ref(1)
const page_size =ref(10)
const goodsList =ref([])
getlist()
function getlist() {
  getGoodsList({ page: page.value, page_size: page_size.value, store_id: id.value }).then(
    (res) => {
      if (res.code === 200) {
        console.log(res, 'www');
        goodsList.value = res.data.shop_del;
      }
    }
  );
}
</script>

<style lang="scss" scoped>
.shop {
  background: #f2f2f2;

  .box {
    width: 687px;
    border-radius: 20px;
    background: #fff;
    margin: 20px auto;

    .tit {
      .img {
        width: 40px;
        height: 40px;
        border-left: 1px dashed #ddd;
        padding-left: 10px;

        img {
          width: 38px;
          height: 38px;
        }
      }

    }

    .van-button {
      border: 0.5px solid #ddd;
      outline: none;
    }
  }

  .waiter {
    width: 687px;
    border-radius: 20px;
    background-color: #fff;
    margin: 20px auto;

    .tips {
      span {
        padding: 5px 10px;
        border: 1px solid #ddd;
        color: #999;
        font-size: 16px;
        width: 100px;
        text-align: center;
        margin-top: 10px;
        border-radius: 20px;
      }

      div {
        color: #999;
        margin: 10px 0;
        font-size: 16px;
      }

    }


  }

  .report {
    width: 687px;
    height: 81px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 1);
    margin: 20px auto;
    text-align: center;

  }

  footer {
    height: 300px;
  }
}
</style>